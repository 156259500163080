import { Box, Button, IconButton, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';

function ItemsPage({ control }) {
  const [state, setState] = control;

  function handleDelete(index) {
    const items = state.items.slice();
    items.splice(index, 1);
    setState({ ...state, items });
  }

  return (
    <>
      <Grid2 container spacing={2}>
        {state.items.length === 0 && (
          <Grid2 xs={12}>
            <Typography p>無牌位紀錄，請加入牌位</Typography>
          </Grid2>
        )}
        {state.items.length > 0 &&
          state.items.map((item, index) => (
            <Grid2 xs={12} key={item.name + item.deceaseds.join('')}>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: 2,
                }}
                onDoubleClick={() =>
                  setState({ ...state, editIndex: index, stage: 'entry' })
                }>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: '1px solid #ccc',
                  }}>
                  <Grid2 container alignItems="center">
                    <Grid2 xs={6}>
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: '#444',
                        }}>
                        {item.name}
                      </Typography>
                    </Grid2>
                    <Grid2
                      xs={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}>
                      <Typography
                        sx={{
                          fontSize: '20px',
                          color: '#444',
                        }}>
                        $
                        {(item.name === '特種牌位'
                          ? 3000
                          : 2000
                        ).toLocaleString()}
                      </Typography>
                      <IconButton>
                        <CloseIcon onClick={() => handleDelete(index)} />
                      </IconButton>
                    </Grid2>
                  </Grid2>
                </Box>
                <Box sx={{ p: 2, fontSize: '16px' }}>
                  <Box>{item.event}</Box>
                  <Box sx={{ mb: 2 }}>
                    <p
                      style={{
                        color: 'rgb(33, 158, 188)',
                        fontSize: 13,
                        fontWeight: 'bold',
                        marginBottom: 2,
                      }}>
                      被超薦人
                    </p>
                    {item.deceaseds.join(' ')}
                  </Box>
                  <Box>
                    <p
                      style={{
                        color: 'rgb(33, 158, 188)',
                        fontSize: 13,
                        fontWeight: 'bold',
                        marginBottom: 2,
                      }}>
                      陽上
                    </p>
                    {item.family.join(' ')}
                  </Box>
                </Box>
              </Box>
            </Grid2>
          ))}
      </Grid2>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          p: 3,
          backgroundColor: 'white',
        }}>
        <Grid2 container spacing={2}>
          <Grid2 xs={6}>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              fullWidth
              onClick={() => setState({ ...state, stage: 'entry' })}>
              輸入牌位
            </Button>
          </Grid2>
          {state.items.length > 0 && (
            <Grid2 xs={6}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                fullWidth
                onClick={() =>
                  setState({
                    ...state,
                    stage: 'submit',
                  })
                }>
                下一步
              </Button>
            </Grid2>
          )}
        </Grid2>
      </Box>
    </>
  );
}

export default ItemsPage;
