import { useNavigate, useSearchParams } from 'react-router-dom';

import React from 'react';
import useHttpClient from '../../services/httpClient/useHttpClient';
import useUser from '../../services/authentication/useUser';

function DropboxConnect(props) {
  const [searchParams] = useSearchParams();
  const [error, setError] = React.useState();
  const navigate = useNavigate();
  const client = useHttpClient();
  const user = useUser();

  React.useEffect(() => {
    client
      .put('/auth/dropbox', {
        code: searchParams.get('code'),
      })
      .then((response) => {
        client
          .put(`/api/user/${user._id}`, {
            dropbox: {
              ...user.dropbox,
              token: response.token,
              expiry: response.expiry,
              refresh: response.refresh,
            },
          })
          .then(() => navigate('/account'));
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) return <div>{error}</div>;
  return <div>正在綁定Dropbox，請勿離開或重新整理頁面。</div>;
}

export default DropboxConnect;
