import SpeedDial, { createAction } from '../../services/speedDial';
import { queryVendor, updateVendor } from '../../fetchers/vendor.fetch';

import { Box } from '@mui/system';
import FileTable from '../../components/FileTable';
import ItemTable from './ItemTable';
import Memos from '../../components/Memos';
import { Paper } from '@mui/material';
import React from 'react';
import Spinner from '../../components/Spinner';
import Tabs from '../../components/Tabs';
import { VendorInfo } from './VendorInfo';
import crumbs from '../../services/crumbs/crumbs.const';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import { useParams } from 'react-router-dom';
import useQuery from '../../services/httpClient/useQuery';
import { useSetMyFilesTag } from '../../components/FileTable/useMyFiles';

function VendorDetail(props) {
  useCrumbs([crumbs.vendorList, crumbs.vendorDetail]);
  const { id } = useParams();
  useSetMyFilesTag(id);
  const vendorQuery = useQuery(['vendor', id], queryVendor({ id }), {
    onSuccess: (data) => setVendor(data),
  });
  const [vendor, setVendor] = React.useState(vendorQuery.data);
  const [itemTable, setItemTable] = React.useState({ mode: '' });
  const vendorUpdateRequest = useMutation(updateVendor, {
    onSuccess: () => {
      toast.success('成功儲存廠商資料');
      vendorQuery.refetch();
    },
  });

  if (vendorQuery.isLoading) return <Spinner />;
  if (vendorQuery.isError) return <p>{vendorQuery.error.toString()}</p>;

  async function handleMemo(memo) {
    try {
      if (typeof memo === 'object' && memo.content.length !== 0) {
        await vendorUpdateRequest.mutateAsync({
          id,
          memos: vendor.memos.concat(memo),
        });
      } else {
        await vendorUpdateRequest.mutateAsync({
          id,
          memos: vendor.memos.filter((m) => m._id.toString() !== memo),
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <Box>
      <Paper elevation={2} sx={{ p: 2 }}>
        <VendorInfo vendor={vendor} setVendor={setVendor} />
      </Paper>
      <Tabs
        sx={{ mt: 2 }}
        tabs={[
          {
            title: '品項列表',
            content: (
              <ItemTable
                tag={vendor._id}
                state={itemTable}
                setState={setItemTable}
              />
            ),
          },
          {
            title: '檔案清單',
            content: <FileTable tag={vendor._id} />,
          },
          {
            title: '請款紀錄',
            content: <p>請款紀錄</p>,
          },
        ]}
      />
      <Memos sx={{ mt: 2 }} memos={vendor.memos} onSubmit={handleMemo} />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(speedDialIcons.save, '儲存', () =>
            vendorUpdateRequest.mutate({ id, ...vendor })
          ),
          createAction(speedDialIcons.addToList, '新增品項', () =>
            setItemTable({ ...itemTable, mode: 'insert' })
          ),
        ]}
      />
    </Box>
  );
}

export default VendorDetail;
