import { Route, Routes, useLocation } from 'react-router-dom';

import AccountDetail from './pages/system/AccountDetail';
import AccountList from './pages/system/AccountList';
import AccountSettings from './pages/system/AccountSettings';
import BrokerDetail from './pages/broker/BrokerDetail';
import BrokerList from './pages/broker/BrokerList';
import CheckRegistration from './pages/event/CheckRegistration';
import CustomerDetail from './pages/customer/CustomerDetail';
import CustomerList from './pages/customer/CustomerList';
import Dashboard from './pages/Dashboard';
import DeceasedList from './pages/deceased/DeceasedList';
import DefaultLayout from './layouts/DefaultLayout';
import DropboxConnect from './pages/system/DropboxConnect';
import EventOrderList from './pages/event/EventOrderList';
import LineConnect from './pages/system/LineConnect';
import LineLogin from './pages/auth/LineLogin';
import OrderDetail from './pages/order/OrderDetail';
import OrderList from './pages/order/OrderList';
import PaymentList from './pages/PaymentList';
import ProductDetail from './pages/product/ProductDetail';
import ProductList from './pages/product/ProductList';
import PurchaseDetail from './pages/purchase/PurchaseDetail';
import PurchaseList from './pages/purchase/PurchaseList';
import QuoteDetail from './pages/quote/QuoteDetail';
import QuoteList from './pages/quote/QuoteList';
import React from 'react';
import ReceiptList from './pages/receipt/ReceiptList';
import ReferralList from './pages/broker/ReferralList';
import RegistrationList from './pages/event/RegistrationList';
import Signin from './pages/auth/Signin';
import Signout from './pages/auth/Signout';
import SiteRegistration from './pages/event/SiteRegistration';
import SummaryDetail from './pages/summary/SummaryDetail';
import SummaryList from './pages/summary/SummaryList';
import TempList from './pages/deceased/TempList';
import Transfer from './pages/product/Transfer';
import VendorDetail from './pages/vendor/VendorDetail';
import VendorList from './pages/vendor/VendorList';
import VisitList from './pages/VisitList';
import extensions from './utils/extensions';
import useAccess from './services/authentication/useAccess';
import useCheckAuthInterval from './services/authentication/useCheckAuthInterval';
import useIsAuthenticated from './services/authentication/useIsAuthenticated';

export const MyFileContext = React.createContext();

function App() {
  const isAuthenticated = useIsAuthenticated();
  useCheckAuthInterval(1000 * 60 * 10);
  const hasAccess = useAccess();
  const location = useLocation();
  const [myFilesTag, setMyFilesTag] = React.useState(null);
  const [myFilesFlag, setMyFilesFlag] = React.useState(Date.now());

  React.useEffect(() => {
    setMyFilesTag(null);
  }, [location]);

  if (!isAuthenticated)
    return (
      <Routes>
        <Route path="line" element={<LineLogin />} />
        <Route path="*" element={<Signin />} />
      </Routes>
    );
  return (
    <MyFileContext.Provider
      value={{ myFilesTag, setMyFilesTag, myFilesFlag, setMyFilesFlag }}>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index path="/" element={<Dashboard />} />
          <Route path="/customer" element={<CustomerList />} />
          <Route path="/customer/:id" element={<CustomerDetail />} />
          <Route path="/product" element={<ProductList />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/deceased" element={<DeceasedList />} />
          <Route path="/temp" element={<TempList />} />
          <Route path="/visit" element={<VisitList />} />
          <Route path="/broker" element={<BrokerList />} />
          <Route path="/broker/:id" element={<BrokerDetail />} />
          <Route path="/referral" element={<ReferralList />} />
          <Route path="/order" element={<OrderList />} />
          <Route path="/order/:id" element={<OrderDetail />} />
          <Route path="/summary" element={<SummaryList />} />
          <Route path="/summary/:id" element={<SummaryDetail />} />
          <Route path="/vendor" element={<VendorList />} />
          <Route path="/vendor/:id" element={<VendorDetail />} />
          <Route path="/quote" element={<QuoteList />} />
          <Route path="/quote/:id" element={<QuoteDetail />} />
          <Route path="/purchase" element={<PurchaseList />} />
          <Route path="/purchase/:id" element={<PurchaseDetail />} />
          <Route path="/receipt" element={<ReceiptList />} />
          <Route path="/payment" element={<PaymentList />} />
          <Route path="/event/order" element={<EventOrderList />} />
          <Route path="/event/registration" element={<RegistrationList />} />
          <Route path="/event/check" element={<CheckRegistration />} />
          <Route
            path="/event/site-registration"
            element={<SiteRegistration />}
          />
          <Route path="/signout" element={<Signout />} />
          <Route path="/line-connect" element={<LineConnect />} />
          <Route path="/dropbox-connect" element={<DropboxConnect />} />
          <Route path="/account" element={<AccountDetail />} />
          {hasAccess('ZAh') && (
            <>
              <Route path="/system/account" element={<AccountList />} />
              <Route path="/system/account/:id" element={<AccountSettings />} />
            </>
          )}
        </Route>
      </Routes>
    </MyFileContext.Provider>
  );
}

export default App;
