import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';
import CategoryIcon from '@mui/icons-material/Category';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import PieChartSharpIcon from '@mui/icons-material/PieChartSharp';
import SettingsIcon from '@mui/icons-material/Settings';

const items = [
  {
    key: 'customer',
    text: '客戶資料',
    link: '/customer',
    icon: <AccountBoxIcon />,
  },
  {
    key: 'product',
    text: '產品資訊',
    icon: <ArticleSharpIcon />,
    items: [
      {
        key: 'product',
        text: '產品資料',
        link: '/product',
      },
      {
        key: 'deceased',
        text: '歿者資料',
        link: '/deceased',
      },
      {
        key: 'temp',
        text: '暫厝紀錄',
        link: '/temp',
      },
      {
        key: 'visit',
        text: '訪客紀錄',
        link: '/visit',
      },
    ],
  },
  {
    key: 'sales',
    text: '業務銷售',
    icon: <PieChartSharpIcon />,
    items: [
      {
        key: 'summary',
        text: '客戶追蹤',
        link: '/summary',
      },
      {
        key: 'broker',
        text: '仲介來源',
        link: '/broker',
      },
      {
        key: 'referral',
        text: '通報紀錄',
        link: '/referral',
      },
    ],
  },
  {
    key: 'admin',
    text: '行政管理',
    icon: <Inventory2SharpIcon />,
    items: [
      {
        key: 'order',
        text: '銷售訂單',
        link: '/order',
      },
      {
        key: 'transfer',
        text: '權狀異動',
        link: '/transfer',
      },
      {
        key: 'payment',
        text: '付費紀錄',
        link: '/payment',
      },
      {
        key: 'receipt',
        text: '統一發票',
        link: '/receipt',
      },
    ],
  },
  {
    key: 'inventory',
    text: '進銷存管理',
    icon: <AccountBalanceWalletIcon />,
    items: [
      {
        key: 'quote',
        text: '報價單',
        link: '/quote',
      },
      {
        key: 'purchase',
        text: '採購單',
        link: '/purchase',
      },
      {
        key: 'vendor',
        text: '廠商列表',
        link: '/vendor',
      },
    ],
  },
  {
    key: 'event',
    text: '法會相關',
    icon: <CategoryIcon />,
    items: [
      {
        key: 'order',
        text: '網路報名',
        link: '/event/order',
      },
      {
        key: 'registration',
        text: '報名紀錄',
        link: '/event/registration',
      },
      {
        key: 'site-registration',
        text: '現場報名',
        link: '/event/site-registration',
        mobile: false,
      },
      {
        key: 'registration',
        text: '複核報名紀錄',
        link: '/event/check',
      },
    ],
  },
  {
    key: 'system',
    text: '系統設定',
    icon: <SettingsIcon />,
    items: [
      {
        key: 'account',
        text: '帳號列表',
        link: '/system/account',
        show: 'ZAh',
      },
    ],
    show: 'ZAh',
  },
];

export default items;
