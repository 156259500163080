import * as React from 'react';

import { Alert, Box, Grid, TextField } from '@mui/material';
import { deleteFile, updateFile } from '../../fetchers/file.fetch';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import LockIcon from '@mui/icons-material/Lock';
import { toast } from 'react-toastify';
import useMutation from '../../services/httpClient/useMutation';
import useUser from '../../services/authentication/useUser';

function FileDialog({ state, setState, onUpdate }) {
  const user = useUser();
  const [name, setName] = React.useState('');
  const [error, setError] = React.useState(null);
  const [deleteDialog, setDeleteDialog] = React.useState({
    open: false,
    name: '',
  });

  const updateFileRequest = useMutation(updateFile, {
    onSuccess: () => {
      toast.success('成功編輯檔案紀錄');
      setState({ ...state, isOpen: false });
      onUpdate();
    },
  });
  const deleteFileRequest = useMutation(deleteFile, {
    onSuccess: () => {
      toast.success('成功刪除檔案紀錄');
      setState({ ...state, isOpen: false });
      onUpdate();
    },
  });

  React.useEffect(() => {
    if (state.file) {
      setName(state.file.desc);
    }
  }, [state]);

  function handleClose() {
    setState({ ...state, isOpen: false });
    setError(null);
  }
  function handleDeleteDialog() {
    setDeleteDialog({ open: true, name: '' });
  }
  function handleDelete() {
    deleteFileRequest.mutate(
      { id: state.file._id },
      {
        onSuccess: () => {
          setDeleteDialog({ open: false, name: '' });
        },
      }
    );
  }
  function handleLock() {
    updateFileRequest.mutate({ id: state.file._id, lock: true });
  }
  function handleSave() {
    if (name.length === 0) return;
    updateFileRequest.mutate({
      id: state.file._id,
      desc: name,
      uploader: {
        _id: user._id,
        name: user.name,
      },
    });
  }

  if (!state.file) return null;

  return (
    <div>
      <Dialog open={state.isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>編輯檔案</DialogTitle>
        <div style={{ padding: 20 }}>
          <TextField
            autoFocus
            label="檔案名稱"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
            error={name.length === 0}
            helperText={name.length === 0 ? '請輸入檔案名稱' : null}
          />
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </div>
        <div style={{ padding: 20 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteDialog}>
                刪除
              </Button>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color="warning"
                onClick={handleLock}>
                <LockIcon sx={{ fontSize: 15, mr: 0.5 }} />
                鎖定
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                sx={{ mr: 2 }}>
                取消
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                儲存
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog open={deleteDialog.open} maxWidth="sm" fullWidth>
        <DialogTitle>確認是否刪除檔案？</DialogTitle>
        <Box sx={{ p: 2 }}>
          <Alert severity="warning">
            <strong>{state.file.desc}</strong> 將會被刪除，請輸入檔案名稱：
          </Alert>
          <TextField
            autoFocus
            label="檔案名稱"
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
            value={deleteDialog.name}
            onChange={(event) =>
              setDeleteDialog({ ...deleteDialog, name: event.target.value })
            }
            error={
              deleteDialog.name.length > 3 &&
              deleteDialog.name !== state.file.desc
            }
            helperText={
              deleteDialog.name.length > 3 &&
              deleteDialog.name !== state.file.desc
                ? '檔案名稱不正確'
                : null
            }
          />
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            {deleteDialog.name.length > 3 &&
              deleteDialog.name === state.file.desc && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2 }}
                  onClick={handleDelete}>
                  確認刪除
                </Button>
              )}
            <Button
              variant="contained"
              color="success"
              onClick={() => setDeleteDialog({ ...deleteDialog, open: false })}>
              取消
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default FileDialog;
