import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { DateTime } from 'luxon';
import Detail from '../../components/Detail';
import React from 'react';
import useHttpClient from '../../services/httpClient/useHttpClient';

const commissionTypes = {
  100: '全佣',
  50: '半佣',
  10: '一成佣',
  0: '無佣',
};

function AddOrderDialog({ state, setState, summary, onOperation }) {
  const client = useHttpClient();

  React.useEffect(() => {
    async function run() {
      const attribution = await getSaleAttribution();
      const commission = await determineCommission();
      const updated = { ...state, saleAttribution: attribution, ...commission };
      setState(updated);
    }
    run();
  }, [summary._id]);

  async function getSaleAttribution() {
    let hasReferral = false;
    let referral = null;
    // get referral
    if (summary.referrals.length !== 0) {
      hasReferral = true;
      referral = await client.get(
        `/api/referral/${encodeURIComponent(summary.referrals[0])}`
      );
    }
    // check viewing
    const viewingEvent = summary.interactions.find(
      (e) => e.interactionType === '帶看'
    );
    // check contract
    const contractEvent = summary.interactions.find(
      (e) => e.interactionType === '簽約'
    );

    const saleAttribution = {
      main: hasReferral ? referral.created.by : summary.interactions[0].by,
      viewing: viewingEvent.by,
      contract: contractEvent.by,
    };
    return saleAttribution;
  }
  async function determineCommission() {
    let data = {
      company: { id: 'company' },
    };

    if (summary.referrals.length !== 0) {
      const referrals = await client.post('/api/referral/query', {
        array: true,
        ids: summary.referrals,
      });
      for (let referral of referrals) {
        referral.date = DateTime.fromISO(referral.date);
        if (referral.broker) {
          if (!data[referral.broker._id]) {
            data[referral.broker._id] = {
              broker: referral.broker,
              date: referral.date,
            };
          } else if (data[referral.broker._id].date > referral.date) {
            data[referral.broker._id].date = referral.date;
          }
          continue;
        }
        if (!data.company.date) data.company.date = referral.date;
        else if (data.company.date > referral.date)
          data.company.date = referral.date;
      }
    }

    for (let event of summary.interactions) {
      event.date = DateTime.fromISO(event.date);
      if (event.broker) {
        if (!data[event.broker._id]) {
          data[event.broker._id] = {
            broker: event.broker,
            date: event.date,
          };
        } else if (data[event.broker._id].date > event.date) {
          data[event.broker._id].date = event.date;
        }
        data[event.broker._id].event = event.interactionType;
        continue;
      }
      if (!data.company.date) data.company.date = event.date;
      else if (data.company.date > event.date) data.company.date = event.date;
    }
    data = Object.values(data);
    const count = Object.values(data).length;
    if (count === 1)
      return {
        commission: { commissionType: 0 },
      };
    const first = data.reduce((early, compare) => {
      if (!early.date) return compare;
      if (!compare.date) return early;
      if (early.date > compare.date) return compare;
      return early;
    }, data[0]);

    let broker;
    if (count === 2) broker = data[1].broker;
    else {
      const contract = data.find((b) => b.interactionType === '簽約');
      if (contract) broker = contract;
      else {
        broker = data.slice(1).reduce((early, compare) => {
          if (early.date > compare.date) return compare;
          return early;
        }, data.slice(1)[0].broker);
      }
    }
    const msg = count > 2 ? `紀錄中有 ${count - 1} 間仲介` : null;
    if (broker && summary.source === '里長') {
      return {
        broker: broker,
        commission: { commissionType: 10, desc: '里長公關件' },
      };
    }
    if (first.id === 'company') {
      if (data.some((broker) => broker.event)) {
        return {
          broker: broker,
          commission: { commissionType: 50, desc: msg },
        };
      } else {
        return {
          commission: { commissionType: 0, desc: msg },
        };
      }
    } else {
      return {
        broker: broker,
        commission: { commissionType: 100, desc: msg },
      };
    }
  }

  return (
    <Dialog
      open={state.open}
      onClose={() => onOperation('cancel')}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>新增訂單</DialogTitle>
      <div style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel>類別</InputLabel>
              <Select
                label="類別"
                value={state.orderType}
                onChange={(event) =>
                  setState({ ...state, orderType: event.target.value })
                }>
                <MenuItem value="一般">一般</MenuItem>
                <MenuItem value="公關">公關</MenuItem>
                <MenuItem value="低收入">低收入</MenuItem>
                <MenuItem value="慈濟">慈濟</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              label="訂單編號"
              value={state.sn}
              onChange={(event) => {
                if (!/^[A-Za-z\d]*$/.test(event.target.value)) return;
                setState({ ...state, sn: event.target.value.toUpperCase() });
              }}
              fullWidth
            />
          </Grid>
          {state.saleAttribution && (
            <Grid item xs={12} sm={6}>
              <Detail title="主辦" value={state.saleAttribution.main?.name} />
              <Detail
                title="帶看"
                value={state.saleAttribution.viewing?.name}
              />
              <Detail
                title="簽約"
                value={state.saleAttribution.contract?.name}
              />
            </Grid>
          )}
          {state.commission && (
            <Grid item xs={12} sm={6}>
              <Detail title="仲介" value={state.broker?.name ?? '無仲介來源'} />
              <Detail
                title="佣金"
                value={commissionTypes[state.commission.commissionType]}
              />
              <Detail title="注意事項" value={state.commission.desc} />
            </Grid>
          )}
          {state.error && (
            <Grid item xs={12}>
              <Alert severity="error">{state.error.toString()}</Alert>
            </Grid>
          )}
        </Grid>
        <div style={{ textAlign: 'right', marginTop: 30 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => onOperation('cancel')}>
            取消
          </Button>
          {state.sn.length !== 0 && state.saleAttribution && state.commission && (
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={() => onOperation('save')}>
              新增
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default AddOrderDialog;
