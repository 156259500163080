import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  buttonClasses,
  tableCellClasses,
} from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';
import { queryQuotes } from '../fetchers/quote.fetch';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useQuery from '../services/httpClient/useQuery';

const StyledTableRow = styled(TableRow)(({ theme, deleted }) => ({
  '&:hover': {
    backgroundColor: blue[50],
  },
  [`& .${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    borderBottom: '2px solid #C0C0C0',
    fontSize: 15,
  },
  [`& .${tableCellClasses.body}`]: {
    fontSize: 15,
    color: deleted ? '#7E7E7E' : 'inherit',
    backgroundColor: deleted ? grey[300] : 'inherit',
  },
}));
const StyledTableCell = styled(TableCell)(({ theme, hidden }) => ({
  [theme.breakpoints.down(hidden ?? 'xs')]: {
    display: 'none',
  },
  [`.${buttonClasses.root}`]: {
    fontSize: 14.5,
    padding: 0,
  },
}));

function QuoteTable({ tag, customerId }) {
  const [quotes, setQuotes] = React.useState([]);
  const navigate = useNavigate();
  const quotesQuery = useQuery(
    ['quotes', { product: tag, customerId }],
    queryQuotes({ product: tag, customerId }),
    {
      placeholder: [],
      staleTime: 30000,
      refetchOnWindowFocus: true,
      onSuccess: async (data) => {
        setQuotes(data);
      },
    }
  );
  return (
    <>
      <TableContainer
        sx={{
          height: 280,
          '& .MuiTableContainer-root::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>報價單編號</StyledTableCell>
              <StyledTableCell>金額</StyledTableCell>
              <StyledTableCell>狀態</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {quotes.map((quote, index) => (
              <StyledTableRow
                key={quote._id}
                onDoubleClick={() => navigate(`/quote/${quote._id}`)}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>
                  <Link
                    sx={{
                      '&:visited': { color: blue[700] },
                      '&:hover': { color: blue[900] },
                      textDecoration: 'none',
                    }}
                    href={`/quote/${quote._id}`}>{`${quote.company}${quote.sn}`}</Link>
                </StyledTableCell>
                <StyledTableCell>
                  {quote.amount.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell>
                  {quote.status === '完款' ? (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: green[700],
                      }}>
                      <CheckCircleIcon
                        sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }}
                      />{' '}
                      {quote.status}
                    </span>
                  ) : (
                    quote.status
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: 'right', marginTop: 15 }}>
        <Typography variant="caption">{`共 ${quotes.length} 筆資料`}</Typography>
      </div>
    </>
  );
}

export default QuoteTable;
