import AddPaymentDialog, {
  addPaymentDialogReducer,
} from '../../components/AddPaymentDialog';
import SpeedDial, { createAction } from '../../services/speedDial';
import { mobileMask, phoneMask } from '../../validations/imask';
import {
  queryCustomerById,
  updateCustomer,
} from '../../fetchers/customer.fetch';

import { Box } from '@mui/system';
import CustomerInfo from './CustomerInfo';
import FileTable from '../../components/FileTable';
import { IdentificationUploadDialog } from './IdentificationUploadDialog';
import Memos from '../../components/Memos';
import PaymentTable from '../../components/PaymentTable';
import ProductTable from '../../components/ProductTable';
import QuoteTable from '../../components/QuoteTable';
import RateReviewIcon from '@mui/icons-material/RateReview';
import React from 'react';
import Sidebar from './Sidebar';
import Spinner from '../../components/Spinner';
import Tabs from '../../components/Tabs';
import { createFile } from '../../fetchers/file.fetch';
import crumbs from '../../services/crumbs/crumbs.const';
import getPostalCode from '../../utils/getPostalCode';
import registerState from '../../utils/registerState';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import { useParams } from 'react-router-dom';
import useQuery from '../../services/httpClient/useQuery';
import { useSetMyFilesTag } from '../../components/FileTable/useMyFiles';

function CustomerDetail(props) {
  useCrumbs([crumbs.customerList, crumbs.customerDetail]);
  const { id } = useParams();
  useSetMyFilesTag(id);
  const [drawer, setDrawer] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [identification, setIdentification] = React.useState({
    open: false,
  });
  const [fileFlag, setFileFlag] = React.useState(Date.now());

  const [paymentDialog, paymentDialogDispatch] = React.useReducer(
    addPaymentDialogReducer,
    { open: false, refresh: Date.now() }
  );
  const customerQuery = useQuery(['customer', id], queryCustomerById({ id }), {
    onSuccess: (data) => {
      data.phone = phoneMask.maskDefault(data.phone);
      data.mobile = mobileMask.maskDefault(data.mobile);
      setCustomer(data);
    },
  });
  const [customer, setCustomer] = React.useState(customerQuery.data);
  const customerRequest = useMutation(updateCustomer, {
    onSuccess: () => {
      toast.success('成功儲存客戶資料');
      customerQuery.refetch();
    },
  });
  const fileCreateRequest = useMutation(createFile);

  if (customerQuery.isLoading) return <Spinner />;
  const registerInput = registerState(customer, setCustomer, errors);

  async function handleMemo(memo) {
    try {
      if (typeof memo === 'object' && memo.content.length !== 0) {
        await customerRequest.mutateAsync({
          id,
          memos: customer.memos.concat(memo),
        });
      } else {
        await customerRequest.mutateAsync({
          id,
          memos: customer.memos.filter((m) => m._id !== memo),
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  function handlePostal(field) {
    return async (e) => {
      let address = e.target.value;
      if (address.length > 5 && !/\d{3}/.test(address.substring(0, 5))) {
        try {
          let postal = await getPostalCode(address);
          if (postal) {
            setCustomer((prev) => ({
              ...prev,
              [field]: `${postal} ${address}`,
            }));
          }
        } catch (error) {
          toast.error(`查詢郵遞區號時發生錯誤${error}`, {
            autoClose: false,
          });
        }
      }
    };
  }
  function handleID() {
    if (!identification.file) return;
    const data = new FormData();
    const ext = identification.file.name.split('.').pop();
    data.append(
      'file',
      identification.file,
      encodeURI(`身分證(${identification.name}).${ext}`)
    );
    data.append('tag', id);
    data.append('lock', true);
    fileCreateRequest.mutateAsync(data, {
      onSuccess: () => {
        customerRequest.mutate(
          {
            id,
            name: identification.name,
            address: identification.address,
            ssn: identification.ssn,
          },
          {
            onSuccess: () => {
              toast.success('成功更新身分證');
              setIdentification({ open: false });
              setFileFlag(Date.now());
            },
          }
        );
      },
      onError: (err) => {
        toast.error(`更新身分證時發生錯誤：${err.toString()}`, {
          autoClose: false,
        });
      },
    });
  }
  function handleSave(event) {
    const errors = {};
    if (customer.name.length === 0) {
      errors.name = { message: '請輸入姓名' };
    }
    if (customerQuery.data.ssn.length > 0 && customer.ssn.length === 0) {
      errors.ssn = { message: '請輸入身分證字號' };
    }
    if (Object.keys(errors).length !== 0) {
      return setErrors(errors);
    }
    customerRequest.mutate({
      id,
      ...customer,
      phone: phoneMask.unmask(customer.phone),
      mobile: mobileMask.unmask(customer.mobile),
    });
  }

  return (
    <Box>
      <CustomerInfo
        customer={customerQuery.data}
        register={registerInput}
        handlePostal={handlePostal}
      />
      <Tabs
        sx={{ mt: 2 }}
        tabs={[
          {
            title: '產品列表',
            content: (
              <ProductTable
                customerId={customer._id}
                name={customer.name}
                onVisit={() => {}}
              />
            ),
          },
          {
            title: '檔案清單',
            content: <FileTable tag={customer._id} flag={fileFlag} />,
          },
          {
            title: '付費紀錄',
            content: (
              <PaymentTable tag={customer._id} flag={paymentDialog.refresh} />
            ),
          },
          {
            title: '報價單',
            content: <QuoteTable customerId={customer._id} />,
          },
        ]}
      />
      <Memos sx={{ mt: 2 }} memos={customer.memos} onSubmit={handleMemo} />
      <Sidebar
        state={customer}
        setState={setCustomer}
        open={drawer}
        setOpen={setDrawer}
      />
      <AddPaymentDialog
        state={{
          isOpen: paymentDialog.open,
          setOpen: (open) =>
            paymentDialogDispatch({ type: open ? 'open' : 'close' }),
        }}
        tag={customer._id}
        payer={{
          _id: customer._id,
          name: customer.name,
        }}
        onSubmit={() => {
          toast.success('成功新增付費紀錄');
          paymentDialogDispatch({ type: 'refresh' });
        }}
      />
      <IdentificationUploadDialog
        customer={customerQuery.data}
        state={identification}
        setState={setIdentification}
        onSave={handleID}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithEdit, '編輯', () =>
            setDrawer(true)
          ),
          createAction(speedDialIcons.save, '儲存', handleSave),
          createAction(speedDialIcons.addPayment, '新增付費紀錄', () =>
            paymentDialogDispatch({ type: 'open' })
          ),
          createAction(<RateReviewIcon />, '更新身分證', () =>
            setIdentification({
              open: true,
              name: customerQuery.data.name,
              ssn: customerQuery.data.ssn,
              address: customerQuery.data.address,
            })
          ),
        ]}
      />
    </Box>
  );
}

export default CustomerDetail;
