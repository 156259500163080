export function serialize(query) {
  if (!query) return query;

  const original = query;

  if (query.startsWith('基')) {
    return query.substring(1).replace('.', '-');
  }

  const prefixMap = {
    福: 'BA',
    祿: 'BB',
    壽: 'BC',
    安: 'BD',
    泰: 'BE',
    吉: 'BF',
    祥: 'BG',
    如: 'FR',
    意: 'FY',
  };

  const firstChar = query[0];
  if (prefixMap[firstChar] && query.includes(firstChar)) {
    return prefixMap[firstChar] + query.substring(1);
  }

  const mapPrefix = {
    忠: 'EA',
    忠I: 'EB',
    忠1: 'EB',
    孝右: 'EC',
    孝: 'ED',
    孝左: 'EE',
    孝I: 'EF',
    孝1: 'EF',
    孝II: 'EG',
    孝2: 'EG',
    仁: 'EH',
    仁II: 'EI',
    仁2: 'EI',
    愛右: 'EJ',
    愛左: 'EK',
    愛I: 'EL',
    愛1: 'EL',
    愛II: 'EN',
    愛2: 'EN',
  };

  try {
    const parts = query.split('.');
    if (parts.length === 2 || parts.length === 3) {
      const combinedPrefix = parts[0]; // 原始前綴（例如「愛II」）
      let prefix = mapPrefix[combinedPrefix];

      if (!prefix) {
        prefix = mapPrefix[parts[0]];
        if (!prefix) return query;
      } else {
        // 如果找到組合前綴，只移除第一段（保留 B01, 020）
        parts.splice(0, 1);
      }

      let product = prefix;

      if (parts.length === 1) {
        product += parts[0];
      } else if (parts.length === 2) {
        const second = isNaN(parseInt(parts[0]))
          ? parts[0].padStart(2, '0')
          : parts[0];
        const third = parts[1].padStart(3, '0');
        product += second + third;
      }

      return product;
    }

    return query;
  } catch (err) {
    console.error(err);
    return original;
  }
}
