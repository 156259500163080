import HomeIcon from '@mui/icons-material/Home';

const crumbs = {
  dashboard: {
    id: 'dashboard',
    label: '儀表板',
    link: '/',
  },
  customerList: {
    id: 'customer',
    label: '客戶列表',
    link: '/customer',
  },
  customerDetail: {
    id: 'customerDetail',
    label: '客戶資料',
    link: (id) => `/customer/${id}`,
  },
  productList: {
    id: 'productList',
    label: '產品列表',
    link: '/product',
  },
  productDetail: {
    id: 'productDetail',
    label: '產品資料',
    link: (id) => `/product/${id}`,
  },
  deceasedList: {
    id: 'deceasedList',
    label: '歿者列表',
    link: '/deceased',
  },
  tempList: {
    id: 'tempList',
    label: '暫厝紀錄',
    link: '/temp',
  },
  brokerList: {
    id: 'brokerList',
    label: '仲介列表',
    link: '/broker',
  },
  brokerDetail: {
    id: 'brokerDetail',
    label: '仲介資料',
    link: (id) => `/broker/${id}`,
  },
  orderList: {
    id: 'orderList',
    label: '訂單列表',
    link: '/order',
  },
  orderDetail: {
    id: 'orderDetail',
    label: '訂單資料',
    link: (id) => `/order/${id}`,
  },
  visitList: {
    id: 'visitList',
    label: '訪客記錄',
    link: '/visit',
  },
  summaryList: {
    id: 'summaryList',
    label: '客戶追蹤列表',
    link: '/summary',
  },
  summaryDetail: {
    id: 'summaryDetail',
    label: '客戶追蹤記錄',
    link: '/summary',
  },
  referral: {
    id: 'referral',
    label: '通報記錄',
    link: '/referral',
  },
  transfer: {
    id: 'transfer',
    label: '權狀異動',
    link: '/transfer',
  },
  receiptList: {
    id: 'receiptList',
    label: '發票列表',
    link: '/receipt',
  },
  paymentList: {
    id: 'paymentList',
    label: '付費記錄列表',
    link: '/payment',
  },
  vendorList: {
    id: 'vendorList',
    label: '廠商列表',
    link: '/vendor',
  },
  vendorDetail: {
    id: 'vendorDetail',
    label: '廠商資料',
    link: (id) => `/vendor/${id}`,
  },
  quoteList: {
    id: 'quoteList',
    label: '報價列表',
    link: '/quote',
  },
  quoteDetail: {
    id: 'quoteDetail',
    label: '報價記錄',
    link: (id) => `/quote/${id}`,
  },
  accountList: {
    id: 'accountList',
    label: '帳號列表',
    link: '/system/account',
  },
  accountSettings: {
    id: 'accountSettings',
    label: '帳號管理',
    link: (id) => `/system/account/${id}`,
  },
  accountDetail: {
    id: 'accountDetail',
    label: '帳號資料',
    link: (id) => `/acccount/${id}`,
  },
  purchaseList: {
    id: 'purchaseList',
    label: '採購列表',
    link: '/purchase',
  },
  purchaseDetail: {
    id: 'purchaseDetail',
    label: '採購紀錄',
    link: (id) => `/puchase/${id}`,
  },
  requestList: {
    id: 'requestList',
    label: '簽呈列表',
    link: (id) => `/request`,
  },
  addRequest: {
    id: 'addRequest',
    label: '管理簽呈',
    link: (id) => `/request/user/${id}`,
  },
  requestDetail: {
    id: 'requestDetail',
    label: '簽呈紀錄',
    link: (id) => `/request/${id}`,
  },
  eventOrderList: {
    id: 'eventOrderList',
    label: '網路報名列表',
    link: '/event/order',
  },
  eventRegistrationList: {
    id: 'eventRegistrationList',
    label: '報名記錄',
    link: '/event/registration',
  },
  siteRegistration: {
    id: 'siteRegistration',
    label: '現場報名',
    link: '/event/site-registration',
  },
  checkRegistration: {
    id: 'checkRegistration',
    label: '複核報名記錄',
    link: '/event/check',
  },
};

export default crumbs;
