import { Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';

function StartPage({ control }) {
  const [state, setState] = control;
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Button
          sx={{ p: 3 }}
          variant="contained"
          size="large"
          fullWidth
          onClick={() =>
            setState({
              ...state,
              stage: 'items',
            })
          }>
          手動輸入
        </Button>
      </Grid2>
      <Grid2 xs={12}>
        <Button
          sx={{ p: 3 }}
          variant="contained"
          size="large"
          color="secondary"
          fullWidth
          onClick={() =>
            setState({
              ...state,
              stage: 'customer',
            })
          }>
          客戶登記
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default StartPage;
