import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';

import KeyboardDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDropUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { grey } from '@mui/material/colors';

export function InteractionTable({ interactions, onCancel }) {
  const [events, setEvents] = React.useState();

  React.useEffect(() => {
    const processed = interactions.map((e) => ({ ...e, collapsed: true }));
    processed.reverse();
    setEvents(processed);
  }, [interactions]);

  function handleCollapse(id) {
    setEvents(
      events.map((e) => (e._id === id ? { ...e, collapsed: !e.collapsed } : e))
    );
  }

  if (!events) return null;

  return (
    <Box
      sx={{
        overflow: 'auto',
        height: '390px',
        [`.${tableCellClasses.root}`]: {
          fontSize: 16,
          py: 1.3,
        },
        [`& .${tableRowClasses.root}.group-header`]: {
          backgroundColor: grey[100],
        },
        [`& .${tableRowClasses.root}.group-header-canceled`]: {
          backgroundColor: grey[500],
          textDecoration: 'line-through',
        },
      }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>日期</TableCell>
            <TableCell>類別</TableCell>
            <TableCell>時間</TableCell>
            <TableCell>隨行禮儀</TableCell>
            <TableCell>業務</TableCell>
            <TableCell>產品</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event, index) => (
            <Row
              key={index}
              event={event}
              onCollapse={handleCollapse}
              onCancel={onCancel}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
function Row({ event, onCollapse, onCancel }) {
  return (
    <>
      <TableRow
        className={event.canceled ? 'group-header-canceled' : 'group-header'}
        onDoubleClick={() => {
          if (event.canceled) return;
          if (event.interactionType !== '簽約') return;
          onCancel(event._id);
        }}>
        <TableCell sx={{ width: '3%' }}>
          {event.desc && (
            <IconButton
              sx={{ p: 0 }}
              size="small"
              onClick={() => onCollapse(event._id)}>
              {!event.collapsed ? (
                <KeyboardDropUpIcon />
              ) : (
                <KeyboardDropDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{event.date?.toString().toDate()}</TableCell>
        <TableCell>{event.interactionType}</TableCell>
        <TableCell>
          {['帶看', '簽約'].includes(event.interactionType) &&
            event.date &&
            `${event.date?.toString().toTime()} ~ ${
              event.end ? event.end?.toString().toTime() : '尚未結束'
            }`}
        </TableCell>
        <TableCell>{event.broker?.name}</TableCell>
        <TableCell>{event.by?.name}</TableCell>
        <TableCell>{event.products.map((p) => p.sn).join(', ')}</TableCell>
      </TableRow>
      {event.desc && (
        <TableRow sx={{ display: event.collapsed ? 'none' : 'table-row' }}>
          <TableCell sx={{ py: '20px !important' }} colSpan={7}>
            <Collapse in={!event.collapsed} timeout="auto" unmountOnExit>
              {event.desc}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
