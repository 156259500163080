import { Box, Button, IconButton, TextField, Typography } from '@mui/material';

import CustomerPage from './CustomerPage';
import EntryPage from './EntryPage';
import Grid2 from '@mui/material/Unstable_Grid2';
import ItemsPage from './ItemsPage';
import React from 'react';
import StartPage from './StartPage';
import SubmitPage from './SubmitPage';
import crumbs from '../../../services/crumbs/crumbs.const';
import useCrumbs from '../../../services/crumbs/useCrumbs';

export default function SiteRegistration(props) {
  useCrumbs([crumbs.eventRegistrationList, crumbs.siteRegistration]);
  const control = React.useState({
    stage: 'start',
    items: [],
  });
  const [state, setState] = control;

  return (
    <Box sx={{ m: 0, p: 2 }}>
      {state.stage === 'start' && <StartPage control={control} />}
      {state.stage === 'items' && <ItemsPage control={control} />}
      {state.stage === 'customer' && <CustomerPage control={control} />}
      {state.stage === 'entry' && <EntryPage control={control} />}
      {state.stage === 'submit' && <SubmitPage control={control} />}
    </Box>
  );
}
