import { Box, Dialog, Typography } from '@mui/material';

import FileTable from './FileTable';
import React from 'react';
import { useMyFiles } from './FileTable/useMyFiles';
import useUser from '../services/authentication/useUser';

function MyFiles({ open, setOpen }) {
  const user = useUser();
  const { myFilesTag } = useMyFiles();
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <Box sx={{ p: 3, pt: 0 }}>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{ color: (theme) => theme.palette.grey[700], pb: 0.5 }}>
            檔案列表
          </Typography>
          <FileTable tag={user._id} download suspendDownload={!myFilesTag} />
        </Box>
      </Box>
    </Dialog>
  );
}

export default MyFiles;
