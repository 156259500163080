import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import {
  createRegistration,
  printRegistration,
  removeSiteRegistration,
} from '../../../fetchers/event.fetch';

import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { DateTime } from 'luxon';
import Grid2 from '@mui/material/Unstable_Grid2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import React from 'react';
import { toast } from 'react-toastify';
import useMutation from '../../../services/httpClient/useMutation';
import useUser from '../../../services/authentication/useUser';

function getEvent() {
  let year = DateTime.now().year - 1911;
  let event = '新春';
  const month = DateTime.now().month;

  if (month === 11 || month === 12) year = year + 1;
  if (month >= 2 && month <= 5) event = '清明';
  if (month >= 6 && month <= 9) event = '中元';
  return `${year}${event}`;
}

function SubmitPage({ control }) {
  const [state, setState] = control;
  const [status, setStatus] = React.useState({
    busy: false,
    print: false,
  });
  const [stopStream, setStopStream] = React.useState(false);
  const user = useUser();
  const [scannerDialog, setScannerDialog] = React.useState({
    open: false,
    barcode: '',
  });
  const createRegistrationRequest = useMutation(createRegistration, {
    onSuccess: (registration) => {
      toast.success('成功新增報名紀錄');
      setStatus((prev) => {
        if (state._id) {
          removeRegistrationRequest.mutate({ id: state._id });
        }
        if (prev.print) {
          printRegistrationRequest.mutate({ id: registration._id });
        }
        setState({ stage: 'start', items: [] });
        return { busy: false, print: false };
      });
    },
  });
  const removeRegistrationRequest = useMutation(removeSiteRegistration);
  const printRegistrationRequest = useMutation(printRegistration, {
    onSuccess: () => {
      toast.success('開始列印...');
    },
  });

  const event = getEvent();

  function closeScannerDialog() {
    setStopStream(() => true);
    setTimeout(() => {
      setScannerDialog({ ...scannerDialog, open: false });
      setStopStream(() => false);
    }, 0);
  }

  function handleSubmit({ print }) {
    if (state.items.length === 0) return toast.error('請加入品項');
    if (!state.phone) return toast.error('請輸入手機');
    if (
      state.phone.length !== 10 ||
      !['09', '02'].includes(state.phone.substring(0, 2))
    )
      return toast.error('手機格式錯誤');

    let exit = false;
    setStatus((prev) => {
      if (prev.busy) exit = true;
      return { busy: true, print };
    });
    if (exit) return;

    const sn = 'TEST02'; // get sn

    const payload = {
      sn,
      items: state.items.map((item) => ({ ...item, event })),
      name: state.name || '現場報名',
      phone: state.phone,
      registry: '現場',
      created: {
        date: new Date(),
        by: {
          _id: user._id,
          name: user.name,
        },
      },
      carrier: state.carrier,
      donation: state.donation,
    };
    createRegistrationRequest.mutate(payload);
  }

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Box sx={{ border: '1px solid #AAA', borderRadius: 2, p: 2, mb: 2 }}>
            總金額：$
            {state.items
              .reduce((acc, cur) => {
                return acc + (cur.name === '特種牌位' ? 3000 : 2000);
              }, 0)
              .toLocaleString()}
          </Box>
        </Grid2>
        <Grid2 xs={12}>客戶</Grid2>
        <Grid2 xs={12}>
          <TextField
            vairant="contained"
            value={state.name || ''}
            onChange={(event) =>
              setState({ ...state, name: event.target.value })
            }
            fullWidth
            label="姓名 (選填)"
          />
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            vairant="contained"
            value={state.phone || ''}
            onChange={(event) =>
              setState({ ...state, phone: event.target.value })
            }
            fullWidth
            label="電話"
          />
        </Grid2>
        <Grid2 xs={12}>發票</Grid2>
        <Grid2 xs={12}>
          <TextField
            vairant="contained"
            value={state.donation || ''}
            onChange={(event) =>
              setState({ ...state, donation: event.target.value })
            }
            fullWidth
            label="愛心碼"
          />
        </Grid2>
        <Grid2 xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="barcode-input">載具</InputLabel>
            <OutlinedInput
              id="barcode-input"
              value={state.carrier || ''}
              onChange={(event) =>
                setState({ ...state, carrier: event.target.value })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() =>
                      setScannerDialog({ ...scannerDialog, open: true })
                    }>
                    <QrCodeScannerIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="載具"
            />
          </FormControl>
        </Grid2>
      </Grid2>
      <Dialog
        sx={{
          p: 0,
        }}
        open={scannerDialog.open}
        onClose={closeScannerDialog}>
        {
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <BarcodeScannerComponent
              width={400}
              height={400}
              stopStream={stopStream}
              onUpdate={(err, result) => {
                if (result) {
                  setState({ ...state, carrier: result.text });
                  closeScannerDialog();
                }
              }}
            />
          </Box>
        }
      </Dialog>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          p: 3,
          backgroundColor: 'white',
        }}>
        <Grid2 container spacing={2}>
          <Grid2 xs={4}>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              fullWidth
              onClick={() => setState({ ...state, stage: 'items' })}>
              上一步
            </Button>
          </Grid2>
          {state.phone &&
            state.phone.length === 10 &&
            state.phone.substring(0, 2) === '09' && (
              <>
                <Grid2 xs={4}>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    disabled={status.busy}
                    fullWidth
                    onClick={() => handleSubmit({ print: false })}>
                    不列印
                  </Button>
                </Grid2>
                <Grid2 xs={4}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    disabled={status.busy}
                    fullWidth
                    onClick={() => handleSubmit({ print: true })}>
                    列印
                  </Button>
                </Grid2>
              </>
            )}
        </Grid2>
      </Box>
    </>
  );
}

export default SubmitPage;
