import { Box, Button, FormControlLabel, Radio, TextField } from '@mui/material';

import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';

function EntryPage({ control }) {
  const [state, setState] = control;
  const [item, setItem] = React.useState({
    name: '特種牌位',
    deceaseds: new Array(2).fill(''),
    family: new Array(5).fill(''),
  });

  React.useEffect(() => {
    if (state.editIndex != null) {
      setItem({
        ...state.items[state.editIndex],
      });
    }
  }, [item.editIndex]);

  function handleChange(event, field, index) {
    const value = event.target.value;
    setItem((prev) => {
      const copy = { ...prev };
      copy[field][index] = value;
      return copy;
    });
  }

  function reset() {
    setItem((prev) => ({
      name: '特種牌位',
      deceaseds: new Array(2).fill(''),
      family: new Array(5).fill(''),
    }));
  }

  function handleClose() {
    reset();
    setState({ ...state, stage: 'items', editIndex: null });
  }

  function handleSave() {
    if (state.editIndex != null) {
      const updated = [...state.items];
      updated[state.editIndex] = item;
      setState({ ...state, items: updated, stage: 'items', editIndex: null });
    } else {
      setState({
        ...state,
        items: [...state.items, item],
        stage: 'items',
        editIndex: null,
      });
    }
  }

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <FormControlLabel
            control={
              <Radio
                checked={item.name === '特種牌位'}
                value="特種牌位"
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
              />
            }
            label="特種牌位"
          />
          <FormControlLabel
            control={
              <Radio
                checked={item.name === '甲種牌位'}
                value="甲種牌位"
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
              />
            }
            label="甲種牌位"
          />
        </Grid2>
        <Grid2 xs={12}>被超薦人</Grid2>
        <Grid2 xs={12}>
          <TextField
            vairant="contained"
            value={item.deceaseds?.[0] || ''}
            onChange={(event) => handleChange(event, 'deceaseds', 0)}
            fullWidth
          />
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            vairant="contained"
            value={item.deceaseds?.[1] || ''}
            onChange={(event) => handleChange(event, 'deceaseds', 1)}
            fullWidth
          />
        </Grid2>
        <Grid2 xs={12}>陽上</Grid2>
        <Grid2 xs={6}>
          <TextField
            vairant="contained"
            value={item.family?.[0] || ''}
            onChange={(event) => handleChange(event, 'family', 0)}
            fullWidth
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            vairant="contained"
            value={item.family?.[1] || ''}
            onChange={(event) => handleChange(event, 'family', 1)}
            fullWidth
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            vairant="contained"
            value={item.family?.[2] || ''}
            onChange={(event) => handleChange(event, 'family', 2)}
            fullWidth
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            vairant="contained"
            value={item.family?.[3] || ''}
            onChange={(event) => handleChange(event, 'family', 3)}
            fullWidth
          />
        </Grid2>
        <Grid2 xs={6}>
          <TextField
            vairant="contained"
            value={item.family?.[4] || ''}
            onChange={(event) => handleChange(event, 'family', 4)}
            fullWidth
          />
        </Grid2>
      </Grid2>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          p: 3,
          backgroundColor: 'white',
        }}>
        <Grid2 container spacing={2}>
          <Grid2 xs={6}>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              fullWidth
              onClick={handleClose}>
              取消
            </Button>
          </Grid2>
          <Grid2 xs={6}>
            {item.deceaseds?.some((d) => d.length > 0) &&
              item.family?.some((f) => f.length > 0) && (
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleSave}
                  fullWidth>
                  儲存
                </Button>
              )}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
}

export default EntryPage;
