import { MyFileContext } from '../../App';
import React from 'react';

export function useMyFiles() {
  const { myFilesTag, myFilesFlag } = React.useContext(MyFileContext);
  return { myFilesTag, myFilesFlag };
}

export function useSetMyFilesTag(tag) {
  const { setMyFilesTag } = React.useContext(MyFileContext);
  setMyFilesTag((t) => tag);
}

export function useSetMyFilesTagFunction() {
  const { setMyFilesTag } = React.useContext(MyFileContext);
  return function (tag) {
    setMyFilesTag((t) => tag);
  };
}

export function useRenewMyFilesFlag() {
  const { setMyFilesFlag } = React.useContext(MyFileContext);
  return function () {
    setMyFilesFlag((f) => Date.now());
  };
}
