import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  createFile,
  queryFilesByTag,
  updateFile,
} from '../../fetchers/file.fetch';
import { useMyFiles, useRenewMyFilesFlag } from './useMyFiles';

import { DateTime } from 'luxon';
import DownloadIcon from '@mui/icons-material/GetApp';
import FileDialog from './FileDialog';
import LockIcon from '@mui/icons-material/Lock';
import MyFiles from '../MyFiles';
import React from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UploadIcon from '@mui/icons-material/Upload';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import useSas from '../../services/authentication/useSas';
import useUser from '../../services/authentication/useUser';

const StyledTableCell = styled(TableCell)(({ theme, hidden }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    borderBottom: '2px solid #C0C0C0',
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
  [theme.breakpoints.down(hidden ?? 'xs')]: {
    display: 'none',
  },
  [`&.${tableCellClasses.body} a`]: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function FileTable({
  tag,
  lock,
  onDrop,
  flag,
  download,
  suspendDownload,
  showMyFiles,
}) {
  const { myFilesTag, myFilesFlag } = useMyFiles();
  const renewMyFilesFlag = useRenewMyFilesFlag();
  const user = useUser();
  const sas = useSas();
  const [dialog, setDialog] = React.useState({
    isOpen: false,
  });
  const [files, setFiles] = React.useState(false);
  const filesQuery = useQuery(['files', tag], queryFilesByTag({ tag }));
  const createFileRequest = useMutation(createFile, {
    onSuccess: () => {
      toast.success('成功上傳檔案');
      filesQuery.refetch();
    },
  });
  const updateFileRequest = useMutation(updateFile, {
    onSuccess: () => {
      filesQuery.refetch();
    },
  });

  React.useEffect(() => {
    filesQuery.refetch();
  }, [flag, myFilesFlag]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
  });
  function handleDrop(acceptedFiles) {
    if (onDrop) {
      const response = onDrop();
      if (!response) return;
    }
    for (let file of acceptedFiles) {
      const data = new FormData();
      data.append('file', file, encodeURI(file.name));
      data.append('tag', tag);
      createFileRequest.mutate(data);
    }
  }
  function handleUploadDownload(id, tags) {
    const filteredTags = tags.filter((t) => t !== (download ? user._id : tag));
    const tagToAdd = download ? myFilesTag : user._id;
    const updatedTags = [...filteredTags, tagToAdd];

    updateFileRequest.mutate(
      {
        id: id,
        tags: updatedTags,
      },
      {
        onSuccess: () => {
          renewMyFilesFlag();
        },
      }
    );
  }

  if (filesQuery.isLoading)
    return (
      <Box sx={{ height: 303 }}>
        <p>Loading...</p>
      </Box>
    );

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <TableContainer
          sx={{
            height: 280,
          }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>檔案名稱</StyledTableCell>
                <StyledTableCell hidden="sm">類型</StyledTableCell>
                <StyledTableCell hidden="md">副檔名</StyledTableCell>
                <StyledTableCell hidden="md">大小</StyledTableCell>
                <StyledTableCell hidden="md">上傳日期</StyledTableCell>
                <StyledTableCell>
                  {showMyFiles && (
                    <IconButton
                      size="small"
                      edge="end"
                      sx={{
                        borderRadius: 2,
                        ml: 1,
                        m: -1,
                        position: 'absolute',
                        top: 0,
                      }}
                      onClick={(e) => setFiles(true)}>
                      <SaveAltIcon
                        sx={{
                          fontSize: 28,
                          color: (theme) => theme.palette.gray.main,
                        }}
                      />
                    </IconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filesQuery.data
                .slice()
                .reverse()
                .map((file, index) => (
                  <TableRow
                    sx={{
                      '&:hover': {
                        backgroundColor: blue[50],
                      },
                    }}
                    key={file._id}
                    onDoubleClick={() => {
                      if (!lock && !file.lock) {
                        setDialog({ isOpen: true, file });
                      }
                    }}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell
                      sx={{ display: 'flex', alignItems: 'center' }}>
                      <a
                        href={`${file.url}?${sas}`}
                        target="_blank"
                        rel="noreferrer">
                        {file.desc}
                      </a>
                      {file.lock && (
                        <LockIcon
                          sx={{
                            fontSize: 16,
                            color: (theme) => theme.palette.grey[600],
                            ml: 0.3,
                            pb: 0.1,
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell hidden="sm">{}</StyledTableCell>
                    <StyledTableCell hidden="md">
                      {file.extension}
                    </StyledTableCell>
                    <StyledTableCell hidden="md">{file.size}</StyledTableCell>
                    <StyledTableCell hidden="md">
                      {DateTime.fromISO(file.uploaded).toFormat(
                        'yyyy-MM-dd HH:mm'
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {!lock && !file.lock && !suspendDownload && (
                        <IconButton
                          size="small"
                          sx={{ m: -1 }}
                          onClick={() =>
                            handleUploadDownload(file._id, file.tags)
                          }>
                          {download ? <DownloadIcon /> : <UploadIcon />}
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ textAlign: 'right', marginTop: 15 }}>
        <Typography variant="caption">{`共 ${filesQuery.data.length} 筆資料`}</Typography>
      </div>
      <FileDialog
        state={dialog}
        setState={setDialog}
        onUpdate={() => filesQuery.refetch()}
      />
      {showMyFiles && <MyFiles open={files} setOpen={setFiles} />}
    </>
  );
}

export default FileTable;
