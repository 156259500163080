import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {
  getSiteRegistrations,
  removeSiteRegistration,
} from '../../../fetchers/event.fetch';

import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { toast } from 'react-toastify';
import useMutation from '../../../services/httpClient/useMutation';
import useQuery from '../../../services/httpClient/useQuery';

function CustomerPage({ control }) {
  const [state, setState] = control;
  const [remove, setRemove] = React.useState(null);
  const registrationsQuery = useQuery(
    ['site-registrations'],
    getSiteRegistrations(),
    {
      staleTime: 0,
    }
  );
  const removeRegistrationRequest = useMutation(removeSiteRegistration, {
    onSuccess: () => {
      setRemove(null);
      toast.success('成功刪除牌位紀錄');
      registrationsQuery.refetch();
    },
  });

  function handleRemove() {
    removeRegistrationRequest.mutate({
      id: remove,
    });
  }

  if (!registrationsQuery.data) return <p>Loading...</p>;
  if (registrationsQuery.data.length === 0)
    return <p>目前沒有客人輸入牌位紀錄</p>;

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12} sx={{ textAlign: 'right' }}>
        <IconButton>
          <CachedIcon onClick={() => registrationsQuery.refetch()} />
        </IconButton>
      </Grid2>
      {registrationsQuery.data.map((customer) => {
        const nt = customer.items.filter(
          (item) => item.name === '特種牌位'
        ).length;
        const nj = customer.items.filter(
          (item) => item.name === '甲種牌位'
        ).length;
        return (
          <Grid2 xs={12}>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
              }}
              onDoubleClick={() =>
                setState({
                  ...state,
                  _id: customer._id,
                  name: customer.name,
                  phone: customer.phone,
                  items: customer.items,
                  stage: 'items',
                })
              }>
              <Box
                sx={{
                  p: 2,
                  borderBottom: '1px solid #ccc',
                }}>
                <Grid2 container alignItems="center">
                  <Grid2 xs={6}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#444',
                      }}>
                      {customer.name} {customer.phone}
                    </Typography>
                  </Grid2>
                  <Grid2
                    item
                    xs={6}
                    sx={{
                      fontSize: '20px',
                      color: '#444',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                    $
                    {customer.items
                      .reduce(
                        (acc, cur) =>
                          acc + (cur.name === '特種牌位' ? 3000 : 2000),
                        0
                      )
                      .toLocaleString()}
                    <IconButton>
                      <CloseIcon onClick={() => setRemove(customer._id)} />
                    </IconButton>
                  </Grid2>
                </Grid2>
              </Box>
              <Box sx={{ p: 2 }}>
                {nt > 0 && <p>特種牌位 x {nt}</p>}
                {nj > 0 && <p>甲種牌位 x {nj}</p>}
              </Box>
            </Box>
          </Grid2>
        );
      })}
      <Dialog open={remove}>
        <DialogTitle>是否要刪除客戶登記？</DialogTitle>
        <DialogContent>
          <Grid2 container sx={{ p: 2 }} spacing={2}>
            <Grid2 xs={6}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => setRemove(null)}>
                取消
              </Button>
            </Grid2>
            <Grid2 xs={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRemove}>
                確定
              </Button>
            </Grid2>
          </Grid2>
        </DialogContent>
      </Dialog>
    </Grid2>
  );
}

export default CustomerPage;
