import {
  Alert,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Container } from './Container';
import { LineLoginButton } from './LineLoginButton';
import React from 'react';
import { signin } from '../../../fetchers/auth.fetch';
import useMutation from '../../../services/httpClient/useMutation';
import useSignUser from '../../../services/authentication/useSignUser';

function Signin(props) {
  const [states, setStates] = React.useState({ username: '', password: '' });
  const [error, setError] = React.useState();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const signUser = useSignUser();
  const signinRequest = useMutation(signin, {
    onSuccess: (data) => {
      signUser(data);
      navigate(location);
    },
    onError: (err) => {
      setError(err);
    },
  });

  React.useEffect(() => {
    const err = searchParams.get('error');
    if (err) {
      setError(err);
    }
  }, [searchParams]);

  function handleSignin() {
    if (states.username.length === 0) return;
    if (states.password.length === 0) return;
    signinRequest.mutate(states);
  }
  function handleChange(field) {
    return (event) => {
      setStates({ ...states, [field]: event.target.value });
    };
  }
  function handleKeyDown(event) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      handleSignin();
    }
  }

  return (
    <Container>
      <Paper elevation={2}>
        <Typography variant="h4">Dashboard</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="帳號"
              value={states.username}
              onChange={handleChange('username')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="密碼"
              type="password"
              value={states.password}
              onChange={handleChange('password')}
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              sx={{ pt: 3 }}>
              <Grid item xs={12} sm={6}>
                <LineLoginButton disabled={signinRequest.isLoading} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ fontWeight: 'bold' }}
                  variant="contained"
                  size="large"
                  onClick={handleSignin}
                  disabled={signinRequest.isLoading}
                  fullWidth>
                  登入
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error.toString()}</Alert>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}

export default Signin;
